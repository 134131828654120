import React, {useEffect, useState} from 'react';
import ShowCounter from "./ShowCounter";
import ExpiredNotice from "./ExpiredNotice";

class Timer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minutes: this.props.minute,
            seconds: '00',
            targetDate: (1000 * 60) * (this.props.minute),
            timerOn: this.props.on,
            start_timestamp: 0
        };
        this.tick = this.tick.bind(this); // bind to the component

        //console.log(this.props.minute+'kkk');
    }

    setMinuteAndSeconds(newTime){
        this.setState(state => ({
            minutes: Math.floor((newTime % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((newTime % (1000 * 60)) / 1000)
        }));
    }

    getTimeFromProps() {
        this.setState( state =>
            ({
                targetDate: (this.state.targetDate-1000)
            }));
        this.setMinuteAndSeconds(this.state.targetDate)
    }

    tick() {
        if(this.props.on===1){
            this.getTimeFromProps();
            this.interval = setInterval(() => {
               this.getTimeFromProps();
            }, 1000);
        }
        else {
            clearInterval(this.interval);
        }
    }

    stop() {
        clearInterval(this.interval);
        this.props.sendData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if (this.state.minutes === 0 && this.state.seconds === 0) {
            this.stop(this)
            return <ExpiredNotice />;
        } else {
            return (
                <div onClick={this.tick}>
                    <ShowCounter
                        minutes={this.state.minutes}
                        seconds={this.state.seconds}
                    />
                </div>
            );
        }
    }
}

export default Timer;