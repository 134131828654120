import React, {Fragment, useEffect} from "react"
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LANGUAGES } from "../constants";
import {dateTimeAfterThreeDays} from "../constants";
import { Translation } from 'react-i18next';
import i18n from '../i18n';
import Timer from "../Timer/Timer";

function withHooks(WrappedComponent) {
    return function(props) {
        const nav = useNavigate();
        return <WrappedComponent nav={nav} {...props}/>
    };
}

class Tablo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'lang': i18n.language,
            'name': JSON.parse(localStorage.getItem('spr_name')),
            'tournament': JSON.parse(localStorage.getItem('tournament')),
            'team_host':  JSON.parse(localStorage.getItem('host')),
            'team_guest':  JSON.parse(localStorage.getItem('guest')),
            'minute':  JSON.parse(localStorage.getItem('minute')),
            'score_host': 0,
            'score_guest': 0,
            'timer': 1,
            'start_time': 0
        };

    }

    logout = () => {
        const navigate = this.props.nav;
        navigate("/", {state:{lang: i18n.language, name: this.state.name}})
    };

    goalHost = () => {
        this.setState(state => ({score_host: this.state.score_host += 1}))
    };

    goalGuest = () => {
        this.setState(state => ({score_guest: this.state.score_guest += 1}))
    }

    timerToggle = () => {
        if(this.state.timer===1)
            this.setState(state => ({timer: this.state.timer = 0}))
        else this.setState(state => ({timer: this.state.timer = 1}))
        console.log(this.state.timer)
    };

    handleNickChange = (e) => {
        this.setState({name: e.target.value});
    };

    handleLangChange = (e) => {
        this.setState({lang: e.target.value});
        i18n.language = e.target.value;
       // console.log(i18n.language);
    };


    handleStopCounter = () => {
        //localStorage.removeItem("spiels");
        let arr = this.getMatches(JSON.parse(localStorage.getItem("spiels")))
        console.log(arr)
        arr.push(this.state)
        console.log(arr)
        localStorage.setItem("spiels", JSON.stringify(arr));
        this.logout()
        //console.log(JSON.parse(localStorage.getItem("spiels")))
    }
    getMatches(json){
        let arr = []
        if(json)
            for(let i = 0; i < json.length; i++) {
               let obj = json[i];
               arr.push(obj);
        }
        return arr;
    }

    render() {
        return (
            <header className="App-header d-grid gap-2">
                <Fragment>
                    <div id="block">
                        <div className="container-fluid" id="tablo">
                            <h2 id="tournament">{this.state.tournament}</h2>
                            <div className="table">
                                <table className="table">
                                    <tbody>
                                    <tr className="teams">
                                        <td id="team_host">{this.state.team_host}</td>
                                        <td id="part"></td>
                                        <td id="team_guest">{this.state.team_guest}</td>
                                    </tr>
                                    <tr className="score">
                                        <td id="score_host" onClick={this.goalHost}>{this.state.score_host}</td>
                                        <td className="timer" onClick={this.timerToggle}>
                                            <Timer
                                                key={this.state.minute}
                                                minute={this.state.minute}
                                                on={this.state.timer}
                                                sendData={this.handleStopCounter}/>
                                        </td>
                                        <td id="score_guest" onClick={this.goalGuest}>{this.state.score_guest}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Fragment>

            </header>
        );
    }
}
export default withHooks(Tablo)
