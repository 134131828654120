import React, {Fragment, useEffect} from "react"
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LANGUAGES } from "../constants";
import { Translation } from 'react-i18next';
import i18n from '../i18n';

function withHooks(WrappedComponent) {
    return function(props) {
        const nav = useNavigate();
        return <WrappedComponent nav={nav} {...props}/>
    };
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'lang': i18n.language,
            'name': '',
            'tournament': '',
            'host': '',
            'guest': '',
            'minute': '',
        };
        //const { t, i18n } = Translation("common");
        //console.log(i18n.language);
    }

    handleClick = () => {
        const navigate = this.props.nav;
        localStorage.setItem('match_id', JSON.stringify(this.state.name));
        localStorage.setItem('tournament', JSON.stringify(this.state.tournament));
        localStorage.setItem('host', JSON.stringify(this.state.host));
        localStorage.setItem('guest', JSON.stringify(this.state.guest));
        localStorage.setItem('minute', JSON.stringify(this.state.minute));
        navigate("/tablo", {state:{lang: i18n.language, name: this.state.name}})
    };

    handleNickChange = (e) => {
        this.setState({name: e.target.value});
    };

    handleLangChange = (e) => {
        this.setState({lang: e.target.value});
        i18n.language = e.target.value;
       // console.log(i18n.language);
    };

    handleTournamentChange = (e) => {
        this.setState({tournament: e.target.value});
    }

    handleHostChange = (e) => {
        this.setState({host: e.target.value});
    }

    handleGuestChange = (e) => {
        this.setState({guest: e.target.value});
    }

    handleMinuteChange = (e) => {
        this.setState({minute: e.target.value});
    }

    deleteMatches = () => {
        const navigate = this.props.nav;
        localStorage.removeItem("spiels");
        navigate("/", {state:{lang: i18n.language, name: this.state.name}})
    }

    render() {
        return (
            <header className="App-header d-grid gap-2 m-1">
                <Fragment>
                    <div className="t_head rounded"><p>Tablo</p></div>
                    <input
                        id="nick-input"
                        type="text"
                        placeholder="Идентификатор матча"
                        value={this.state.name}
                        onInput={this.handleNickChange}/>
                    <input
                        id="tournament-input"
                        type="text"
                        placeholder="Турнир"
                        value={this.state.tournament}
                        onInput={this.handleTournamentChange}/>
                    <input
                        id="host-input"
                        type="text"
                        placeholder="Хозяин"
                        value={this.state.host}
                        onInput={this.handleHostChange}/>
                    <input
                        id="guest-input"
                        type="text"
                        placeholder="Гость"
                        value={this.state.guest}
                        onInput={this.handleGuestChange}/>
                    <input
                        id="minute-input"
                        type="text"
                        placeholder="minute"
                        value={this.state.minute}
                        onInput={this.handleMinuteChange}/>

                </Fragment>
                <p className="btn-block">
                    <Button className="weiter w-100 btn btn-lg mb-1" variant="secondary" size="lg" onClick={this.handleClick} >
                        <Translation>
                            {
                                t => <p>{t('Запускаем')}</p>
                            }
                        </Translation>
                    </Button>
                    <Button className="weiter w-100 btn btn-lg mb-1" variant="secondary" size="lg" onClick={this.deleteMatches} >
                        <Translation>
                            {
                                t => <p>{t('Стереть игры')}</p>
                            }
                        </Translation>
                    </Button>
                </p>
                <p>
                    {JSON.parse(localStorage.getItem("spiels")) ?
                        JSON.parse(localStorage.getItem("spiels")).map(({ name, tournament, team_host, team_guest, score_host, score_guest }) => (
                            <div key={name} className="matches">
                                <p>{tournament}</p>
                                <p>{team_host} <span>{score_host}:{score_guest}</span> {team_guest}</p>
                                <hr/>
                            </div>
                        )) : ''}
                </p>
            </header>
        );
    }
}

export default withHooks(Login)
