import React from "react";

const ExpiredNotice = () => {
    return (
        <div className="expired-notice">
            <span>Матч окончен</span>
        </div>
    );
};

export default ExpiredNotice;