export const LANGUAGES = [
    { label: "English", code: "en" },
    { label: "Deutsch", code: "de" },
    { label: "Русский", code: "ru" },
    { label: "All", code: "all" },
];

export const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
export const NOW_IN_MS = new Date().getTime();

export const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
