import React from "react";

const  ShowCounter = ({ minutes, seconds }) => {
    return (
        <div className="show-counter" >
            <span id="minute">{minutes}</span>:
            <span id="second">
                {
                    seconds<10 && seconds>0 ?
                        '0'+seconds : (seconds===0) ? '00' :
                        seconds
                }
            </span>
        </div>
    );
};

export default ShowCounter;